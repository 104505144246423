export enum LINKS {
   // TOKEN = 'http://localhost:8000/auth/refresh-token',
   TOKEN = 'https://sub.xn--y9ap3dco.xn--y9a3aq/auth/refresh-token',
   REGISTER = '/auth/register',
   SIGNIN = '/auth/login',
   LOGOUT = '/auth/logout',
   GET_USER = '/api/user',
   ADDCAT = '/api/add-cat',
   VIEWCATS = '/api/view-cats',
   VIEWCURRENTCAT = '/viewcurrentcat.php',
}

// auth
export type RegForm = {
   email: string,
   username: string,
   firstname: string,
   surname: string,
   password: string,
   password_confirmation: string,
}

export type SigninForm = {
   email: string,
   password: string,
   remember?: boolean
}

export type IUser = {
   id: number,
   username: string,
   name: string,
   surname: string,
   email: string
   paym?: number;
   created_at?: string;
}

// cats
export type IAddCat = {
   uid: number,
   cat: number,
}

export type IViewCats = {
   user_cat_id: number,
   cat: number
}

// api 
export type IHeaders = {
   [key: string]: string
}




