export const isWebView = () => {
   const userAgent = navigator.userAgent || (window as any).opera;
   const normalizedUserAgent = userAgent.toLowerCase();
   const standalone = (navigator as any).standalone;

   // For iOS
   const isIos =
      /iphone|ipod|ipad/.test(normalizedUserAgent) ||
      (userAgent.includes("Mac") && "ontouchend" in document);

   // For Android
   const isAndroid = /android/.test(normalizedUserAgent);

   // For Safari
   const isSafari = /safari/.test(normalizedUserAgent) && !/chrome/.test(normalizedUserAgent);

   // For WebView
   const isWebview =
      (isAndroid && /; wv\)/.test(normalizedUserAgent)) ||
      (isIos && !standalone && !isSafari);

   return isWebview;
}