import React from 'react'
import st from './header.module.scss'
import cn from 'classnames'
import HeaderF from './HeaderF/HeaderF'
import HeaderS from './HeaderS/HeaderS'

export interface IHeader {
  isWebViewUser: boolean
}

const Header: React.FC<IHeader> = ({ isWebViewUser }) => {
  return (
    <header className={st.header}>
      <div className={cn('wrapper', st.wrapper)}>
        <HeaderF
          isWebViewUser={isWebViewUser}
        />
        <HeaderS />
      </div>
    </header>
  )
}

export default Header
