import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/routes";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { Toaster } from "react-hot-toast";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes />
      </ScrollToTop>
      <Toaster />
    </BrowserRouter>
  );
};

export default App;
